import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  MenuItem,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const AddProduct = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [cities, setCities] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get("REACT_APP_API_URL/api/cities", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCities(response.data);
      } catch (error) {
        console.error("Şehirler alınırken hata oluştu:", error);
        setError("Şehirler yüklenirken bir hata oluştu.");
      }
    };
    fetchCities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Form Validasyonu
    if (
      name.trim() === "" ||
      isNaN(price) ||
      price <= 0 ||
      city === "" ||
      region.trim() === "" ||
      !description.trim() ||
      !image
    ) {
      setError("Lütfen tüm alanları doğru şekilde doldurun.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("price", price);
      formData.append("city", city);
      formData.append("region", region);
      formData.append("description", description);
      formData.append("image", image);

      const response = await axios.post(
        "REACT_APP_API_URL/api/products/add",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage("Ürün başarıyla eklendi!");
      setTimeout(() => {
        setMessage("");
        navigate("/admin/dashboard");
      }, 2000);
    } catch (error) {
      console.error("Ürün ekleme hatası:", error);
      setError(
        error.response?.data?.message ||
          "Ürün eklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError("");
    setMessage("");
  };

  return (
    <Box
      className="flex flex-col h-screen bg-gradient-to-b from-gray-100 via-gray-200 to-gray-300 text-gray-900 justify-center items-center"
      sx={{ padding: 2 }}
    >
      <Card
        className="w-full max-w-lg rounded-lg shadow-2xl overflow-hidden transform hover:scale-105 transition-transform duration-300"
        sx={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
      >
        <CardContent className="p-6">
          <Typography
            variant="h4"
            component="h2"
            className="text-center font-bold mb-4"
            sx={{
              color: "#374151",
              textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            Ürün Ekle
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Ürün Adı"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddCircleOutlineIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Fiyat"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MonetizationOnIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Şehir"
                  select
                  variant="outlined"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                >
                  <MenuItem value="">
                    <em>Şehir Seçin</em>
                  </MenuItem>
                  {cities.map((city) => (
                    <MenuItem key={city._id} value={city._id}>
                      {city.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bölge"
                  variant="outlined"
                  fullWidth
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PlaceIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Açıklama"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{
                    backgroundColor: "#4f46e5",
                    color: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  Fotoğraf Yükle
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                  <PhotoCamera sx={{ ml: 1 }} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  className="mt-4 py-3 font-semibold text-lg shadow-md transform hover:scale-105 transition-transform duration-300"
                  sx={{
                    backgroundColor: "#4f46e5",
                    color: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Ürün Ekle"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        open={!!error || !!message}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {error ? (
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="success">
            {message}
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
};

export default AddProduct;
