import React, { createContext, useContext, useState } from "react";

// AuthContext oluştur
const AuthContext = createContext();

// Kullanılacak Hook
export const useAuth = () => useContext(AuthContext);

// Sağlayıcı bileşeni
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  // Giriş yap işlevi
  const login = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
  };

  // Çıkış yap işlevi
  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
