import React from "react";
import { useNavigate } from "react-router-dom";
import BackgroundWrapper from "../components/BackgroundWrapper"; // Arka plan bileşeni
import darkLogo from "../assets/dark.png"; // Import the logo
import { motion, AnimatePresence } from "framer-motion";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleCitySelect = (city) => {
    navigate(`/products?city=${city}`);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const logoVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 10,
      },
    },
  };

  const buttonVariants = {
    rest: { scale: 1, boxShadow: "0px 0px 8px rgb(0,0,0,0)" },
    hover: {
      scale: 1.05,
      boxShadow: "0px 0px 8px rgb(255,255,255,0.3)",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10,
      },
    },
    tap: { scale: 0.95 },
  };

  return (
    <BackgroundWrapper>
      <AnimatePresence>
        <motion.div
          className="flex flex-col items-center justify-center text-center h-full w-full"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.img
            src={darkLogo}
            alt="DARKAGE Shop Logo"
            className="w-96 h-auto mb-16 mt-[-100px]"
            variants={logoVariants}
          />

          <motion.h1
            className="text-5xl md:text-7xl font-bold text-white drop-shadow-lg mb-4"
            variants={itemVariants}
          >
            Select A City
          </motion.h1>
          <motion.p
            className="mt-4 text-lg text-white drop-shadow-md mb-8"
            variants={itemVariants}
          >
            By selecting your city, you can access the best products.
          </motion.p>
          <motion.div
            className="mt-8 flex flex-wrap justify-center gap-6"
            variants={itemVariants}
          >
            {["Dubai"].map((city, index) => (
              <motion.button
                key={city}
                className="px-8 py-4 text-xl text-white font-semibold rounded-lg shadow-lg transition-all"
                style={{
                  background: `linear-gradient(to right, ${
                    index === 0
                      ? "#4ade80, #3b82f6"
                      : index === 1
                      ? "#facc15, #ef4444"
                      : "#a855f7, #ec4899"
                  })`,
                }}
                onClick={() => handleCitySelect(city)}
                variants={buttonVariants}
                initial="rest"
                whileHover="hover"
                whileTap="tap"
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.8 }}
              >
                {city}
              </motion.button>
            ))}
          </motion.div>
        </motion.div>
      </AnimatePresence>
      <motion.footer
        className="mt-32 text-center text-white max-w-2xl mx-auto bg-black bg-opacity-50 p-8 rounded-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7, duration: 0.5 }}
      >
        <h3 className="text-2xl font-bold mb-6">DARKAGE Shop Advantages</h3>
        <ul className="text-sm space-y-3">
          <li className="font-semibold">
            ✓ Expert Support (@Darkageshop_manager)
          </li>
          <li className="font-semibold">
            ✓ Quality products and a guarantee of the best service
          </li>
          <li className="font-semibold">
            ✓ The best conditions for your money and security
          </li>
          <li className="font-semibold">
            ✓ Customer satisfaction, quality products, anonymity, and privacy
          </li>
        </ul>
        <p className="text-lg mt-6 font-semibold">Enjoy every day with us.</p>
      </motion.footer>
    </BackgroundWrapper>
  );
};

export default LandingPage;
