import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom"; // useNavigate eklendi
import BackgroundWrapper from "../components/BackgroundWrapper";
import darkLogo from "../assets/dark.png"; // Import the logo
import { motion, AnimatePresence } from "framer-motion";

const RegionList = () => {
  const [regions, setRegions] = useState([]);
  const { search } = useLocation();
  const navigate = useNavigate(); // useNavigate kullanımı
  const productId = new URLSearchParams(search).get("product"); // URL'den ürün ID'si alınır

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products/details/${productId}`
        );
        setRegions(response.data.regions || []); // `regions` alanını alıyoruz, boş ise boş dizi döner
      } catch (error) {
        console.error("Bölge bilgileri alınırken hata oluştu:", error);
      }
    };

    fetchRegions();
  }, [productId]);

  const handleRegionClick = (region) => {
    navigate(`/payment?product=${productId}&region=${region}`); // Payment sayfasına yönlendirme
  };

  return (
    <BackgroundWrapper>
      <div className="flex flex-col items-center justify-center min-h-screen py-16 px-4 sm:px-6 lg:px-8">
        {/* Logo - Adjusted size and margin to match LandingPage and ProductList */}
        <img
          src={darkLogo}
          alt="DARKAGE Shop Logo"
          className="w-96 h-auto mb-16 mt-[-100px]"
        />

        <h2 className="text-5xl font-bold text-center mb-8 text-white">
          Select a Region
        </h2>
        <div className="flex flex-wrap justify-center gap-6 mt-10 max-w-4xl">
          {regions.map((region, index) => (
            <div
              key={index}
              className="w-[232.36px] h-[124px] bg-gradient-to-r from-red-400 to-yellow-500 text-white rounded-lg shadow-lg flex flex-col justify-center items-center hover:from-red-500 hover:to-yellow-600 transition-all transform hover:scale-105 cursor-pointer"
              onClick={() => handleRegionClick(region)} // Yönlendirme fonksiyonu
            >
              <div className="text-lg font-semibold">{region}</div>
            </div>
          ))}
        </div>
        <motion.footer
          className="mt-32 text-center text-white max-w-2xl mx-auto bg-black bg-opacity-50 p-8 rounded-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.5 }}
        >
          <h3 className="text-2xl font-bold mb-6">DARKAGE Shop Advantages</h3>
          <ul className="text-sm space-y-3">
            <li className="font-semibold">
              ✓ Expert Support (@Darkageshop_manager)
            </li>
            <li className="font-semibold">
              ✓ Quality products and a guarantee of the best service
            </li>
            <li className="font-semibold">
              ✓ The best conditions for your money and security
            </li>
            <li className="font-semibold">
              ✓ Customer satisfaction, quality products, anonymity, and privacy
            </li>
          </ul>
          <p className="text-lg mt-6 font-semibold">Enjoy every day with us.</p>
        </motion.footer>
      </div>
    </BackgroundWrapper>
  );
};

export default RegionList;
