import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaUser, FaLock } from "react-icons/fa"; // React Icons kullanıldı

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("REACT_APP_API_URL/api/admin/login", {
        username,
        password,
      });
      localStorage.setItem("token", response.data.token);
      setError(""); // Hata mesajını temizle
      navigate("/admin/dashboard");
    } catch (error) {
      setError("Geçersiz kullanıcı adı veya şifre!");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="relative w-full max-w-md p-8 bg-white bg-opacity-10 rounded-lg shadow-lg backdrop-blur-lg backdrop-filter z-10">
        <h2 className="text-4xl font-bold text-center text-white">
          Admin Girişi
        </h2>
        {error && <p className="text-center text-red-400 mt-2">{error}</p>}{" "}
        {/* Hata mesajı */}
        <form onSubmit={handleSubmit} className="space-y-6 mt-6">
          <div className="relative flex items-center">
            <FaUser className="absolute left-3 text-gray-400" />
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="w-full pl-10 pr-4 py-3 text-white bg-transparent border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Kullanıcı Adı"
            />
          </div>
          <div className="relative flex items-center">
            <FaLock className="absolute left-3 text-gray-400" />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full pl-10 pr-4 py-3 text-white bg-transparent border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Şifre"
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 font-semibold text-white bg-gradient-to-r from-blue-500 to-purple-600 rounded-md shadow hover:from-blue-600 hover:to-purple-700 transition-all transform hover:scale-105"
          >
            Giriş Yap
          </button>
        </form>
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500 via-purple-600 to-pink-500 opacity-20 rounded-lg blur-lg -z-10"></div>
      </div>
    </div>
  );
};

export default Login;
