import React from "react";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  BuildingOfficeIcon,
  ShoppingCartIcon,
  CogIcon,
  WalletIcon,
  ArrowLeftOnRectangleIcon, // Logout iconu eklendi
} from "@heroicons/react/24/outline";
import { useAuth } from "../context/AuthContext"; // AuthContext kullanımı

const Sidebar = () => {
  const { logout } = useAuth(); // logout fonksiyonunu kullan

  const handleLogout = () => {
    logout(); // Çıkış yap
    window.location.href = "/admin/login"; // Giriş sayfasına yönlendir
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-b from-indigo-700 via-pink-700 to-indigo-900 text-white shadow-2xl">
      <div className="flex items-center justify-center py-6 border-b border-indigo-500">
        <h1 className="text-2xl font-extrabold tracking-wider text-indigo-200 uppercase">
          DARKAGE
        </h1>
      </div>
      <nav className="flex-1 px-4 py-8 space-y-4">
        <Link
          to="/admin/dashboard"
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-indigo-800 hover:scale-105 rounded-lg"
        >
          <HomeIcon className="w-5 h-5 mr-3" />
          Dashboard
        </Link>
        <Link
          to="/admin/add-city"
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-indigo-800 hover:scale-105 rounded-lg"
        >
          <BuildingOfficeIcon className="w-5 h-5 mr-3" />
          Şehir Ekle
        </Link>
        <Link
          to="/admin/add-product"
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-indigo-800 hover:scale-105 rounded-lg"
        >
          <ShoppingCartIcon className="w-5 h-5 mr-3" />
          Ürün Ekle
        </Link>
        <Link
          to="/admin/order-management"
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-indigo-800 hover:scale-105 rounded-lg"
        >
          <CogIcon className="w-5 h-5 mr-3" />
          Sipariş Yönetimi
        </Link>
        <Link
          to="/admin/wallet-management"
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-indigo-800 hover:scale-105 rounded-lg"
        >
          <WalletIcon className="w-5 h-5 mr-3" />
          Cüzdan Yönetimi
        </Link>
        <Link
          to="/admin/reviews"
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-indigo-800 hover:scale-105 rounded-lg"
        >
          <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-3" />
          Reviews
        </Link>
        {/* Çıkış Yap butonu */}
        <button
          onClick={handleLogout}
          className="flex items-center px-4 py-3 text-base font-semibold transition-all duration-300 transform hover:bg-red-600 hover:scale-105 rounded-lg mt-8"
        >
          <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-3" />
          Çıkış Yap
        </button>
      </nav>
      <div className="p-4 text-sm text-center text-indigo-300 border-t border-indigo-500">
        &copy; 2024 Admin Panel
      </div>
    </div>
  );
};

export default Sidebar;
