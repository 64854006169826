import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import darkLogo from "../assets/dark.png"; // Import the logo
// Yeni import
import { motion } from "framer-motion";
import { FaShoppingCart, FaStar } from "react-icons/fa"; // FaInfoCircle yerine FaStar eklendi

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [cities, setCities] = useState([]); // Şehirleri tutmak için state
  const { search } = useLocation();
  const navigate = useNavigate();
  const cityName = new URLSearchParams(search).get("city").trim().toLowerCase(); // Şehir ismini küçük harf ve boşlukları temizleyerek al

  useEffect(() => {
    // Şehirleri almak için API çağrısı
    const fetchCities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/cities`
        );
        setCities(response.data);
      } catch (error) {
        console.error("Şehirler yüklenirken hata oluştu:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    if (!cityName || cities.length === 0) return;

    // Şehir ismine göre ID'yi bulma
    const normalizeString = (str) =>
      str
        .toLowerCase()
        .normalize("NFD") // Türkçe karakterleri ve aksanları temizler
        .replace(/[\u0300-\u036f]/g, ""); // Tüm aksan ve işaretleri temizler

    const selectedCity = cities.find(
      (city) => normalizeString(city.name) === normalizeString(cityName) // Normalize edilmiş isimlerle karşılaştırma yap
    );

    if (!selectedCity) {
      console.error("Şehir bulunamadı, lütfen doğru bir şehir seçin.");
      console.log("Seçilen şehir:", cityName); // Seçilen şehir adını yazdırarak hatayı anlamaya çalışalım
      return;
    }

    const fetchProducts = async () => {
      try {
        // Şehir ID'sine göre ürünleri çekme
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products/${selectedCity._id}`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Ürünler yüklenirken hata oluştu:", error);
      }
    };

    fetchProducts();
  }, [cityName, cities]);

  const handleProductClick = (productId) => {
    navigate(`/regions?product=${productId}`); // Bölge sayfasına yönlendirme
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={
          index < Math.round(rating) ? "text-yellow-400" : "text-gray-400"
        }
        size={14}
      />
    ));
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <motion.img
        src={darkLogo}
        alt="DARKAGE Shop Logo"
        className="w-96 h-auto mb-16 mt-[-100px]"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      />

      <motion.h2
        className="text-5xl font-bold text-center mb-8 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        Select a Product
      </motion.h2>

      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-10 max-w-7xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        {products.map((product) => (
          <motion.div
            key={product._id}
            className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl shadow-xl overflow-hidden border border-gray-700"
            whileHover={{
              y: -5,
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => handleProductClick(product._id)}
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-2xl font-bold text-white">
                  {product.name}
                </h3>
                <div className="flex items-center bg-gray-700 px-2 py-1 rounded-full">
                  {renderStars(5)}
                  <span className="text-yellow-400 ml-1 text-xs font-semibold">
                    5.0
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-center mb-6">
                <span className="text-3xl font-extrabold text-green-400">
                  ${product.price}
                </span>
                <span className="text-sm text-gray-400">Premium Quality</span>
              </div>
              <button
                className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-3 rounded-lg transition-colors duration-300 w-full"
                onClick={(e) => {
                  e.stopPropagation();
                  handleProductClick(product._id);
                }}
              >
                <FaShoppingCart className="mr-2" />
                Select Product
              </button>
            </div>
            <div className="bg-gray-700 px-6 py-3 flex justify-between items-center">
              <span className="text-xs text-gray-300">Secure transaction</span>
              <span className="text-xs text-gray-300">24/7 Support</span>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <motion.footer
        className="mt-32 text-center text-white max-w-2xl mx-auto bg-black bg-opacity-50 p-8 rounded-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7, duration: 0.5 }}
      >
        <h3 className="text-2xl font-bold mb-6">DARKAGE Shop Advantages</h3>
        <ul className="text-sm space-y-3">
          <li className="font-semibold">
            ✓ Expert Support (@Darkageshop_manager)
          </li>
          <li className="font-semibold">
            ✓ Quality products and a guarantee of the best service
          </li>
          <li className="font-semibold">
            ✓ The best conditions for your money and security
          </li>
          <li className="font-semibold">
            ✓ Customer satisfaction, quality products, anonymity, and privacy
          </li>
        </ul>
        <p className="text-lg mt-6 font-semibold">Enjoy every day with us.</p>
      </motion.footer>
    </div>
  );
};

export default ProductList;
