import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FiCopy,
  FiCheck,
  FiAlertCircle,
  FiLoader,
  FiPackage,
  FiCreditCard,
} from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundWrapper from "../components/BackgroundWrapper";

const API_URL = process.env.REACT_APP_API_URL || "REACT_APP_API_URL";

const Confirmation = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [productInfo, setProductInfo] = useState(null);

  const { search } = useLocation();
  const productId = new URLSearchParams(search).get("product");
  const paymentMethod = new URLSearchParams(search).get("method");

  useEffect(() => {
    fetchOrderDetails();
  }, [productId, paymentMethod]);

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const [orderResponse, walletResponse] = await Promise.all([
        axios.get(`${API_URL}/api/order/details/${productId}`),
        axios.get(`${API_URL}/api/wallet/${paymentMethod}`),
      ]);
      setOrderDetails(orderResponse.data.orderDetails);
      setWalletAddress(walletResponse.data.address);
    } catch (error) {
      setError("Failed to fetch order details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentConfirmation = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/api/order/check_order`, {
        code: orderDetails.orderCode,
      });

      if (response.data.status === "paid") {
        setPaymentConfirmed(true);
        setProductInfo(response.data);
        toast.success("Payment confirmed successfully!");
      } else {
        toast.warning(
          "Payment not yet completed. Please complete the payment and try again."
        );
      }
    } catch (error) {
      toast.error("Error confirming payment. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.info("Copied to clipboard");
  };

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen message={error} />;

  return (
    <BackgroundWrapper>
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <Header />
          <main className="mt-10 space-y-8">
            <OrderSummary orderDetails={orderDetails} />
            <PaymentInstructions
              orderDetails={orderDetails}
              walletAddress={walletAddress}
              paymentMethod={paymentMethod}
              onCopy={copyToClipboard}
            />
            <ConfirmationSection
              loading={loading}
              paymentConfirmed={paymentConfirmed}
              onConfirm={handlePaymentConfirmation}
              productInfo={productInfo}
            />
          </main>
        </div>
        <ToastContainer position="bottom-right" theme="dark" />
      </div>
    </BackgroundWrapper>
  );
};

const Header = () => (
  <motion.header
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="text-center"
  >
    <h1 className="text-4xl font-extrabold text-white drop-shadow-lg">
      Payment Confirmation
    </h1>
    <p className="mt-2 text-xl text-white text-opacity-80">
      Complete your purchase securely
    </p>
  </motion.header>
);

const OrderSummary = ({ orderDetails }) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className="bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg shadow-xl overflow-hidden"
  >
    <div className="px-6 py-8">
      <h2 className="text-2xl font-semibold text-white flex items-center">
        <FiPackage className="mr-2 text-blue-400" /> Order Summary
      </h2>
      <dl className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
        <InfoItem
          label="Order Number"
          value={orderDetails.orderNumber || "Unknown"}
        />
        <InfoItem label="Order Code" value={orderDetails.orderCode} />
        <InfoItem
          label="Product"
          value={orderDetails.productName}
          className="sm:col-span-2"
        />
      </dl>
    </div>
  </motion.section>
);

const PaymentInstructions = ({
  orderDetails,
  walletAddress,
  paymentMethod,
  onCopy,
}) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.4 }}
    className="bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg shadow-xl overflow-hidden"
  >
    <div className="px-6 py-8">
      <h2 className="text-2xl font-semibold text-white flex items-center">
        <FiCreditCard className="mr-2 text-blue-400" /> Payment Instructions
      </h2>
      <div className="mt-6 space-y-6">
        <div>
          <p className="text-sm font-medium text-gray-400">Amount to Pay</p>
          <p className="mt-1 text-3xl font-bold text-blue-400">
            ${orderDetails.price} {paymentMethod}
          </p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-400">
            Send payment to this address:
          </p>
          <div className="mt-2 relative rounded-md shadow-sm">
            <input
              type="text"
              readOnly
              value={walletAddress}
              className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-600 rounded-md bg-gray-800 text-white"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                onClick={() => onCopy(walletAddress)}
                className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
              >
                <FiCopy className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </motion.section>
);

const ConfirmationSection = ({
  loading,
  paymentConfirmed,
  onConfirm,
  productInfo,
}) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.6 }}
    className="bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg shadow-xl overflow-hidden"
  >
    <div className="px-6 py-8">
      <h2 className="text-2xl font-semibold text-white mb-6">
        Confirm Your Payment
      </h2>
      {!paymentConfirmed ? (
        <button
          onClick={onConfirm}
          disabled={loading}
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
        >
          {loading ? (
            <>
              <FiLoader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              Processing...
            </>
          ) : (
            <>
              <FiCheck className="-ml-1 mr-3 h-5 w-5" />
              Confirm Payment
            </>
          )}
        </button>
      ) : (
        <div className="text-center">
          <FiCheck className="mx-auto h-12 w-12 text-green-400" />
          <h3 className="mt-2 text-xl font-medium text-white">
            Payment Confirmed
          </h3>
          {productInfo && (
            <div className="mt-4">
              <p className="text-sm text-gray-300">{productInfo.description}</p>
              {productInfo.imagePath && (
                <img
                  src={`${API_URL}/${productInfo.imagePath}`}
                  alt="Product"
                  className="mt-4 mx-auto max-w-full h-auto rounded-lg shadow-md"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  </motion.section>
);

const InfoItem = ({ label, value, className = "" }) => (
  <div className={className}>
    <dt className="text-sm font-medium text-gray-400">{label}</dt>
    <dd className="mt-1 text-lg font-semibold text-white">{value}</dd>
  </div>
);

const LoadingScreen = () => (
  <BackgroundWrapper>
    <div className="flex justify-center items-center min-h-screen">
      <FiLoader className="animate-spin text-6xl text-blue-400" />
    </div>
  </BackgroundWrapper>
);

const ErrorScreen = ({ message }) => (
  <BackgroundWrapper>
    <div className="flex justify-center items-center min-h-screen">
      <div className="bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-lg border-l-4 border-red-500 p-6 rounded-lg shadow-xl">
        <div className="flex items-center">
          <FiAlertCircle
            className="h-6 w-6 text-red-500 mr-3"
            aria-hidden="true"
          />
          <h3 className="text-lg font-medium text-red-400">Error</h3>
        </div>
        <div className="mt-3">
          <p className="text-sm text-red-300">{message}</p>
        </div>
      </div>
    </div>
  </BackgroundWrapper>
);

export default Confirmation;
