import React from "react";
import BackgroundWrapper from "../components/BackgroundWrapper";

const Rules = () => {
  return (
    <BackgroundWrapper>
      <div className="container mx-auto px-4 py-12 text-white font-sans">
        <h1 className="text-5xl font-bold mb-8 text-center text-yellow-400 drop-shadow-lg">
          Rules
        </h1>
        <div className="bg-black bg-opacity-70 p-8 rounded-lg shadow-xl max-w-4xl mx-auto">
          <p className="mb-6 text-lg leading-relaxed">
            Our team has been working since 2021 and throughout the entire
            period, we have carefully studied all the nuances of working in the
            Emirates. Purchasing items from us is very simple and, most
            importantly, safe. We provide services in two secure ways:
          </p>

          <h2 className="text-3xl font-semibold mt-8 mb-4 text-yellow-300">
            1) Buried Treasure
          </h2>
          <h2 className="text-3xl font-semibold mb-6 text-yellow-300">
            2) Courier Delivery
          </h2>

          <h3 className="text-2xl font-semibold mt-8 mb-4 text-green-400">
            1) What is Buried Treasure?
          </h3>
          <p className="mb-6 text-lg leading-relaxed">
            Buried treasure means an item is waiting, hidden junk the location
            you selected from our website. The buried treasure could be inside
            something (a package, napkin, plaster, box) or on a magnet.
          </p>

          <ul className="list-disc pl-8 mb-6 space-y-3 text-lg">
            <li>
              1.2 - Buried purchases are guaranteed for 6 hours from the moment
              of purchase; after this time, we will not assist you if it is not
              found. Please keep this in mind when purchasing!
            </li>
            <li>
              1.3 -If you can't find the treasure, don’t panic. Re-read the
              description of the treasure and compare the area with the image to
              make sure it matches. Take a photo of the area where the buried
              treasure is located (the photo must be taken from the same angle
              as the one provided with your order).
            </li>
            <li>1.4 - You must contact the operator regarding this issue.</li>
            <li>
              1.5 - Please be aware that your issue will be resolved as quickly
              as possible. Requests without photos will not be considered.
            </li>
            <li>
              1.6 - If the product is not there for reasons unrelated to the
              customer, our store will refund the money to ensure customer
              satisfaction.
            </li>
          </ul>

          <h3 className="text-2xl font-semibold mt-8 mb-4 text-green-400">
            2) Courier Delivery
          </h3>
          <p className="mb-6 text-lg leading-relaxed">
            When you use the courier option, after the payment step, you will be
            asked to provide a location, and the courier will immediately depart
            to deliver your order. Your order will be delivered within 30
            minutes (in rare cases, up to 60 minutes).
          </p>

          <ul className="list-disc pl-8 mb-6 space-y-3 text-lg">
            <li>
              2.1 - Delivery is made after payment, and you provide the address
              when placing the order. The courier will deliver the product to
              the specified location within the indicated time frame.
            </li>
            <li>
              2.2 - The delivery process includes: Product, quantity, and
              delivery location.
            </li>
            <li>
              2.3 - To maintain customer privacy, the courier will leave the
              order at a location close to the customer (100-200 meters).
            </li>
          </ul>

          <p className="mb-6 text-lg leading-relaxed">
            Both Buried Treasure and Courier Delivery are handled by
            professionally trained individuals. There are quality hiding spots
            far from potential dangers, without the threat of cameras.
          </p>

          <p className="mb-8 text-lg leading-relaxed">
            Since there are no searches conducted in Dubai, you do not need to
            worry about your safety in a foreign country.
          </p>

          <h3 className="text-2xl font-semibold mt-8 mb-4 text-green-400">
            Other Rules
          </h3>
          <ol className="list-decimal pl-8 mb-6 space-y-3 text-lg">
            <li>
              We place great importance on Quality, Security, and Privacy in our
              store, so we do not need any personal information from you.
              Therefore, registration is not required when shopping.
            </li>
            <li>The store guarantees privacy for all customers!</li>
            <li>The store guarantees the quality of all its products!</li>
            <li>
              By making a purchase from us, you automatically agree to our
              terms.
            </li>
            <li>
              To complete a successful purchase, please select the city, product
              name, and then the quantity. After that, our service will prompt
              you to choose a payment method and will inform you of the exact
              amount and time for payment. The service management is not
              responsible for errors related to incorrect payments.
            </li>
            <li>
              If you make a payment after the allotted time for the order, money
              or products will not be reissued or refunded. We strongly
              recommend making your payments on time to avoid losing your money.
            </li>
            <li>
              Payments made to a different wallet (one that does not correspond
              to the order details) will not be refunded. Please be cautious!
            </li>
            <li>
              You must behave politely when communicating with store management.
              Inappropriate users will be blocked.
            </li>
          </ol>

          <p className="mt-8 text-xl font-semibold text-red-400">
            Update 09/23/24: Due to changes in Telegram's policy of sharing user
            data with law enforcement, all our sales will now be conducted
            exclusively through our website.
          </p>
        </div>
      </div>
    </BackgroundWrapper>
  );
};

export default Rules;
