import React from "react";
import backgroundImage from "../assets/bg.png"; // Görseli import ettik

const BackgroundWrapper = ({ children }) => {
  return (
    <div
      className="min-h-screen bg-cover bg-center flex flex-col"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="relative z-10 flex-grow flex flex-col">{children}</div>
    </div>
  );
};

export default BackgroundWrapper;
