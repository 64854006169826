import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import {
  FiPlus,
  FiEdit2,
  FiTrash2,
  FiX,
  FiCheck,
  FiCopy,
} from "react-icons/fi";
import { FaBitcoin } from "react-icons/fa";
import { SiLitecoin, SiMonero } from "react-icons/si";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_URL = process.env.REACT_APP_API_URL || "REACT_APP_API_URL";

const WalletManagement = () => {
  const [wallets, setWallets] = useState([]);
  const [newWallet, setNewWallet] = useState({
    address: "",
    paymentMethod: "USDT-TRC20",
  });
  const [editingWallet, setEditingWallet] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchWallets();
  }, []);

  const fetchWallets = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/api/wallet`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWallets(response.data);
    } catch (error) {
      toast.error("Error fetching wallets");
    }
  };

  const handleAddWallet = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/api/wallet/add`,
        newWallet,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setWallets([...wallets, response.data.wallet]);
      setNewWallet({ address: "", paymentMethod: "USDT-TRC20" });
      setIsModalOpen(false);
      toast.success("Wallet added successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Error adding wallet");
    }
  };

  const handleUpdateWallet = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${API_URL}/api/wallet/${editingWallet._id}`,
        editingWallet,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setWallets(
        wallets.map((w) => (w._id === editingWallet._id ? editingWallet : w))
      );
      setEditingWallet(null);
      toast.success("Wallet updated successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating wallet");
    }
  };

  const handleDeleteWallet = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/api/wallet/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWallets(wallets.filter((wallet) => wallet._id !== id));
      toast.success("Wallet deleted successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting wallet");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.info("Copied to clipboard");
  };

  const getPaymentMethodIcon = (method) => {
    switch (method) {
      case "USDT-TRC20":
        return <FiPlus className="text-green-500" />;
      case "Bitcoin":
        return <FaBitcoin className="text-orange-500" />;
      case "Litecoin":
        return <SiLitecoin className="text-blue-400" />;
      case "Monero":
        return <SiMonero className="text-orange-600" />;
      default:
        return <FiPlus className="text-gray-500" />;
    }
  };

  return (
    <div className="min-h-screen p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-5xl font-bold mb-12 text-center text-gray-800">
          Wallet Management
        </h1>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsModalOpen(true)}
          className="mb-8 px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 flex items-center justify-center w-full md:w-auto shadow-lg"
        >
          <FiPlus className="mr-2" /> Add New Wallet
        </motion.button>

        <div className="space-y-6">
          {wallets.map((wallet) => (
            <motion.div
              key={wallet._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-white rounded-xl p-6 shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-200"
            >
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center">
                  {getPaymentMethodIcon(wallet.paymentMethod)}
                  <h3 className="text-xl font-semibold ml-2 text-gray-800">
                    {wallet.paymentMethod}
                  </h3>
                </div>
                <div className="flex space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setEditingWallet(wallet)}
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    <FiEdit2 size={18} />
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleDeleteWallet(wallet._id)}
                    className="text-red-600 hover:text-red-800 transition-colors duration-200"
                  >
                    <FiTrash2 size={18} />
                  </motion.button>
                </div>
              </div>
              <p className="text-gray-600 break-all mb-4">{wallet.address}</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => copyToClipboard(wallet.address)}
                className="flex items-center text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
              >
                <FiCopy className="mr-1" /> Copy Address
              </motion.button>
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <Modal
            title="Add New Wallet"
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleAddWallet}
          >
            <input
              type="text"
              placeholder="Wallet Address"
              value={newWallet.address}
              onChange={(e) =>
                setNewWallet({ ...newWallet, address: e.target.value })
              }
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            />
            <select
              value={newWallet.paymentMethod}
              onChange={(e) =>
                setNewWallet({ ...newWallet, paymentMethod: e.target.value })
              }
              className="mt-4 w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            >
              <option value="USDT-TRC20">USDT-TRC20</option>
              <option value="Bitcoin">Bitcoin</option>
              <option value="Litecoin">Litecoin</option>
              <option value="Monero">Monero</option>
            </select>
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {editingWallet && (
          <Modal
            title="Edit Wallet"
            onClose={() => setEditingWallet(null)}
            onSubmit={handleUpdateWallet}
          >
            <input
              type="text"
              placeholder="Wallet Address"
              value={editingWallet.address}
              onChange={(e) =>
                setEditingWallet({ ...editingWallet, address: e.target.value })
              }
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            />
            <select
              value={editingWallet.paymentMethod}
              onChange={(e) =>
                setEditingWallet({
                  ...editingWallet,
                  paymentMethod: e.target.value,
                })
              }
              className="mt-4 w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            >
              <option value="USDT-TRC20">USDT-TRC20</option>
              <option value="Bitcoin">Bitcoin</option>
              <option value="Litecoin">Litecoin</option>
              <option value="Monero">Monero</option>
            </select>
          </Modal>
        )}
      </AnimatePresence>

      <ToastContainer position="bottom-right" theme="colored" />
    </div>
  );
};

const Modal = ({ title, children, onClose, onSubmit }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
      className="bg-white rounded-xl p-8 w-full max-w-md shadow-2xl"
    >
      <h2 className="text-2xl font-bold mb-6 text-gray-800">{title}</h2>
      {children}
      <div className="mt-8 flex justify-end space-x-4">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClose}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
        >
          <FiX className="inline-block mr-1" /> Cancel
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onSubmit}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
        >
          <FiCheck className="inline-block mr-1" /> Submit
        </motion.button>
      </div>
    </motion.div>
  </motion.div>
);

export default WalletManagement;
