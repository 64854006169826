import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddCity from "./pages/AddCity";
import AddProduct from "./pages/AddProduct";
import Dashboard from "./pages/Dashboard";
import OrderManagement from "./pages/OrderManagement";
import Sidebar from "./components/Sidebar";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import LandingPage from "./pages/LandingPage";
import ProductList from "./pages/ProductList";
import Rules from "./pages/Rules";
import ProductDetail from "./pages/ProductDetail";
import Navbar from "./components/Navbar";
import RegionList from "./pages/RegionList";
import Payment from "./pages/Payment";
import Confirmation from "./pages/Confirmation";
import WalletManagement from "./pages/WalletManagement";
import { AuthProvider } from "./context/AuthContext"; // AuthProvider eklendi
import ReviewsPage from "./pages/ReviewsPage";
import AdminReviewsPage from "./pages/AdminReviewsPage";
import BackgroundWrapper from "./components/BackgroundWrapper";

function App() {
  return (
    <AuthProvider>
      <Router>
        <BackgroundWrapper>
          <Navbar />
          <div className="pt-16">
            {" "}
            {/* Navbar'ın altında boşluk bırakmak için */}
            <Routes>
              {/* Admin paneli giriş ve korumalı rotalar */}
              <Route path="/admin/login" element={<Login />} />
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <div className="flex">
                      <Sidebar />
                      <div className="flex-1 p-8 bg-gray-100 min-h-screen">
                        <Routes>
                          <Route path="dashboard" element={<Dashboard />} />
                          <Route path="add-city" element={<AddCity />} />
                          <Route path="add-product" element={<AddProduct />} />
                          <Route
                            path="order-management"
                            element={<OrderManagement />}
                          />
                          <Route
                            path="wallet-management"
                            element={<WalletManagement />}
                          />
                          <Route
                            path="reviews"
                            element={<AdminReviewsPage />}
                          />
                        </Routes>
                      </div>
                    </div>
                  </PrivateRoute>
                }
              />

              {/* Müşteri tarafı rotaları */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/products" element={<ProductList />} />
              <Route path="/product/:id" element={<ProductDetail />} />
              <Route path="/regions" element={<RegionList />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route path="/rules" element={<Rules />} />
              <Route path="/reviews" element={<ReviewsPage />} />
            </Routes>
          </div>
        </BackgroundWrapper>
      </Router>
    </AuthProvider>
  );
}

export default App;
