import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BackgroundWrapper from "../components/BackgroundWrapper"; // Arka plan görselini ekliyoruz

const Payment = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const productId = new URLSearchParams(search).get("product");
  const region = new URLSearchParams(search).get("region");

  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products/details/${productId}`
        );
        setProduct(response.data.product);
      } catch (error) {
        console.error(
          "An error occurred while retrieving product information:",
          error
        );
      }
    };

    fetchProductDetails();
  }, [productId]);

  const paymentMethods = [
    { name: "USDT-TRC20", icon: "💎" },
    { name: "Bitcoin", icon: "₿" },
    { name: "Litecoin", icon: "Ł" },
    { name: "Monero", icon: "ɱ" },
  ];

  const handlePayment = (method) => {
    navigate(
      `/confirmation?product=${productId}&region=${region}&method=${method}`
    );
  };

  if (!product) {
    return (
      <BackgroundWrapper>
        <div className="text-center text-white">
          Product or city information not found.
        </div>
      </BackgroundWrapper>
    );
  }

  return (
    <BackgroundWrapper>
      <div className="flex flex-col items-center justify-center min-h-screen bg-opacity-70 text-white p-4">
        <div className="bg-gradient-to-br from-gray-900 to-gray-800 p-8 rounded-3xl shadow-2xl w-full max-w-md backdrop-blur-lg border border-gray-700 relative overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div>
          <h2 className="text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
            Payment Confirmation
          </h2>
          <div className="space-y-6">
            <InfoItem icon="🏙️" label="City" value={product.city.name} />
            <InfoItem icon="🗺️" label="Region" value={region} />
            <InfoItem icon="🛍️" label="Product" value={product.name} />
            <InfoItem
              icon="💰"
              label="Price"
              value={`${product.price} USDT`}
              highlight
            />
          </div>
          <div className="mt-8 text-center text-sm text-gray-400">
            Please select a payment method
          </div>
        </div>

        <div className="mt-8 w-full max-w-md space-y-4">
          {paymentMethods.map((method) => (
            <button
              key={method.name}
              onClick={() => handlePayment(method.name)}
              className="w-full py-4 bg-gray-800 text-white font-semibold rounded-xl hover:bg-gray-700 transition transform hover:scale-102 shadow-lg flex items-center justify-center space-x-2 border border-gray-700"
            >
              <span className="text-2xl">{method.icon}</span>
              <span>{method.name} </span>
            </button>
          ))}
        </div>
      </div>
    </BackgroundWrapper>
  );
};

const InfoItem = ({ icon, label, value, highlight = false }) => (
  <div className="flex items-center space-x-4">
    <div className="text-2xl">{icon}</div>
    <div className="flex-grow">
      <div className="text-sm text-gray-400">{label}</div>
      <div
        className={`font-semibold ${
          highlight ? "text-xl text-blue-400" : "text-white"
        }`}
      >
        {value}
      </div>
    </div>
  </div>
);

export default Payment;
