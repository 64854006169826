import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <nav className="bg-[#121212] text-[#777] p-4 shadow-md font-myriadpro fixed w-full z-50">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex justify-center items-center w-full">
          <div className="hidden md:flex space-x-8 justify-center w-full">
            <Link
              to="/"
              className="text-[15px] hover:text-white transition-all"
            >
              Catalog
            </Link>
            <Link
              to="/reviews"
              className="text-[15px] hover:text-white transition-all"
            >
              Reviews
            </Link>
            <Link
              to="/rules"
              className="text-[15px] hover:text-white transition-all"
            >
              Rules
            </Link>
            <a
              href="https://t.me/Darkageshop_manager"
              className="text-[15px] hover:text-white transition-all"
            >
              Support
            </a>
          </div>

          <button
            className="md:hidden flex items-center text-gray-200 hover:text-white"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden bg-[#121212] mt-2 py-2 space-y-2 flex flex-col items-center">
          <Link
            to="/"
            className="text-[15px] text-[#777] hover:text-white transition-all"
          >
            Catalog
          </Link>
          <Link
            to="/reviews"
            className="text-[15px] text-[#777] hover:text-white transition-all"
          >
            Reviews
          </Link>
          <Link
            to="/rules"
            className="text-[15px] text-[#777] hover:text-white transition-all"
          >
            Rules
          </Link>
          <a
            href="https://t.me/Darkageshop_manager"
            className="text-[15px] text-[#777] hover:text-white transition-all"
          >
            Support
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
