import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Dashboard = () => {
  const [cityCount, setCityCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [recentProducts, setRecentProducts] = useState([]);
  const [chartData, setChartData] = useState({
    series: [{ data: [] }],
    options: {
      chart: { type: "bar" },
      xaxis: { categories: [] },
    },
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cityResponse = await axios.get("REACT_APP_API_URL/api/cities", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const productResponse = await axios.get(
          "REACT_APP_API_URL/api/products",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setCityCount(cityResponse.data.length);
        setProductCount(productResponse.data.length);
        setRecentProducts(productResponse.data.slice(-5));

        setChartData({
          series: [
            { data: productResponse.data.map((product) => product.price || 0) },
          ],
          options: {
            chart: { type: "bar" },
            xaxis: {
              categories: productResponse.data.map(
                (product) => product.name || "Bilinmiyor"
              ),
            },
          },
        });
      } catch (error) {
        console.error("Veriler alınırken hata oluştu:", error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = useCallback((product) => {
    setCurrentProduct(product);
    setIsEditModalOpen(true);
  }, []);

  const handleSaveEdit = async () => {
    if (!currentProduct) return;
    try {
      await axios.put(
        `REACT_APP_API_URL/api/products/${currentProduct._id}`,
        currentProduct,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setRecentProducts((prevProducts) =>
        prevProducts.map((product) =>
          product._id === currentProduct._id ? currentProduct : product
        )
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Ürün güncellenirken hata oluştu:", error);
    }
  };

  const handleDelete = useCallback((product) => {
    setCurrentProduct(product);
    setIsDeleteModalOpen(true);
  }, []);

  const confirmDelete = async () => {
    if (!currentProduct) return;
    try {
      await axios.delete(
        `REACT_APP_API_URL/api/products/${currentProduct._id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setRecentProducts((prevProducts) =>
        prevProducts.filter((product) => product._id !== currentProduct._id)
      );
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Ürün silinirken hata oluştu:", error);
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Admin Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="p-6 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-lg shadow-md">
          <h3 className="text-lg font-medium">Toplam Şehir Sayısı</h3>
          <p className="text-4xl">{cityCount}</p>
        </div>
        <div className="p-6 bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-lg shadow-md">
          <h3 className="text-lg font-medium">Toplam Ürün Sayısı</h3>
          <p className="text-4xl">{productCount}</p>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-2xl font-semibold text-gray-700">
          Son Eklenen Ürünler
        </h3>
        <TableContainer component={Paper} className="mt-4 shadow-md">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ürün Adı</TableCell>
                <TableCell>Fiyat</TableCell>
                <TableCell>Şehir</TableCell>
                <TableCell>Bölge</TableCell>
                <TableCell align="right">İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentProducts.map((product) => (
                <TableRow key={product._id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.price} TL</TableCell>
                  <TableCell>{product.city?.name || "Bilinmiyor"}</TableCell>
                  <TableCell>{product.region || "Bilinmiyor"}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(product)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(product)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="mt-6">
        <h3 className="text-2xl font-semibold text-gray-700">
          Ürün Fiyat Grafiği
        </h3>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>

      {/* Düzenle Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Ürün Düzenle</h3>
            <input
              type="text"
              value={currentProduct.name}
              onChange={(e) =>
                setCurrentProduct({ ...currentProduct, name: e.target.value })
              }
              className="w-full p-2 border rounded mb-4 focus:outline-none focus:border-blue-500"
              placeholder="Ürün Adı"
            />
            <input
              type="number"
              value={currentProduct.price}
              onChange={(e) =>
                setCurrentProduct({ ...currentProduct, price: e.target.value })
              }
              className="w-full p-2 border rounded mb-4 focus:outline-none focus:border-blue-500"
              placeholder="Fiyat"
            />
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleSaveEdit}
            >
              Kaydet
            </button>
            <button
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => setIsEditModalOpen(false)}
            >
              İptal
            </button>
          </div>
        </div>
      )}

      {/* Sil Onay Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">
              Bu ürünü silmek istediğinize emin misiniz?
            </h3>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={confirmDelete}
            >
              Evet, Sil
            </button>
            <button
              className="ml-2 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              İptal
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
