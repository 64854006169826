import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  InputAdornment,
} from "@mui/material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";

const AddCity = () => {
  const [name, setName] = useState("");
  const [regions, setRegions] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const regionsArray = regions.split(",").map((region) => region.trim());

    // Form Validasyonu
    if (name.trim() === "") {
      setError("Şehir adı boş olamaz.");
      return;
    }

    if (regionsArray.length === 0 || regionsArray[0] === "") {
      setError("En az bir bölge girilmelidir.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/cities/add`,
        { name, regions: regionsArray },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMessage("Şehir başarıyla eklendi!");
      setTimeout(() => {
        setMessage("");
        navigate("/admin/dashboard");
      }, 2000);
    } catch (error) {
      setError("Şehir eklenirken bir hata oluştu.");
    }
  };

  const handleClose = () => {
    setError("");
    setMessage("");
  };

  return (
    <Box
      className="flex flex-col h-screen bg-gradient-to-b from-gray-100 via-gray-200 to-gray-300 text-gray-900 justify-center items-center"
      sx={{ padding: 2 }}
    >
      <Card
        className="w-full max-w-lg rounded-lg shadow-2xl overflow-hidden transform hover:scale-105 transition-transform duration-300"
        sx={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
      >
        <CardContent className="p-6">
          <Typography
            variant="h4"
            component="h2"
            className="text-center font-bold mb-4"
            sx={{
              color: "#374151",
              textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            Şehir Ekle
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Şehir Adı"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bölgeler (virgülle ayırın)"
                  variant="outlined"
                  fullWidth
                  value={regions}
                  onChange={(e) => setRegions(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MapIcon />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="mt-4 py-3 font-semibold text-lg shadow-md transform hover:scale-105 transition-transform duration-300"
                  sx={{
                    backgroundColor: "#4f46e5",
                    color: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  Şehir Ekle
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      {/* Hata ve Başarı Mesajları */}
      <Snackbar
        open={!!error || !!message}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {error ? (
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="success">
            {message}
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
};

export default AddCity;
