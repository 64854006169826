import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BackgroundWrapper from "../components/BackgroundWrapper"; // BackgroundWrapper bileşeni

const ProductDetail = () => {
  const { id } = useParams(); // URL'den ürün ID'si alınır
  const [product, setProduct] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(""); // Seçilen bölgeyi tutmak için state

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `REACT_APP_API_URL/api/products/details/${id}` // Ürün detayları için backend API çağrısı
        );
        setProduct(response.data);
      } catch (error) {
        console.error("Ürün detayları yüklenirken hata oluştu:", error);
      }
    };

    fetchProduct();
  }, [id]);

  if (!product) {
    return <div>Yükleniyor...</div>;
  }

  // Bölge verisi dizi değilse boş bir diziye dönüştür
  const regions = Array.isArray(product.region) ? product.region : [];

  // Bölge Seçim İşlemi
  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
  };

  // Ödeme İşlemi
  const handlePurchase = () => {
    if (!selectedRegion) {
      alert("Lütfen bir bölge seçin.");
      return;
    }
    alert(`Ödeme işlemi yapılacak: ${product.name} - Bölge: ${selectedRegion}`);
  };

  return (
    <BackgroundWrapper>
      <h2 className="text-3xl font-bold mb-4">{product.name}</h2>
      <p className="text-xl text-green-600 font-bold mb-4">
        {product.price} TL
      </p>
      <p className="text-lg font-semibold text-gray-700">Bölge Seçin:</p>

      {/* Bölge Seçim Listesi */}
      <div className="flex flex-wrap justify-center gap-4 mb-6">
        {regions.length > 0 ? (
          regions.map((region, index) => (
            <button
              key={index}
              className={`px-4 py-2 rounded-lg border ${
                selectedRegion === region
                  ? "bg-green-500 text-white"
                  : "bg-white text-gray-700 hover:bg-gray-200"
              } transition`}
              onClick={() => handleRegionSelect(region)}
            >
              {region}
            </button>
          ))
        ) : (
          <p>Bölge bilgisi mevcut değil.</p>
        )}
      </div>

      {/* Satın Al Butonu */}
      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition"
        onClick={handlePurchase}
      >
        Satın Al
      </button>
    </BackgroundWrapper>
  );
};

export default ProductDetail;
